.page {
  transition: transform 400ms ease-in-out;
}
.page-enter {
  transform: translate3d(100%, 0, 0);
}
.page-enter-active {
  transform: translate3d(0, 0, 0);
}
.page-exit {
  transform: translate3d(-100%, 0, 0);
}
.prev.page-enter {
  transform: translate3d(-100%, 0, 0);
}
.prev.page-enter-active {
  transform: translate3d(0, 0, 0);
}
.prev.page-exit {
  transform: translate3d(100%, 0, 0);
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
}
.page-footer {
  flex: 0 0 auto;
}
.page {
  background-color: #fafafb;
  top: 0;
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.page .page-header {
  display: flex;
  flex-direction: row;
  height: 50px;
  background-color: #1f3c4f;
}
.page .page-header .page-title {
  flex: 1 1 auto;
  color: #ffffff;
  font-size: 20px;
  align-self: center;
  text-align: center;
}
.page .page-content-top {
  flex: 0 0 auto;
}
.page .page-content {
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15);
  margin: 30px;
  background-color: #ffffff;
  height: 100%;
  overflow: hidden;
}
.page .page-footer {
  justify-content: flex-end;
  display: flex;
  padding: 5px;
}
.page .page-footer button.footer-button {
  margin: 5px 10px 5px 0;
}
.center {
  align-self: center;
  text-align: center;
}
.hidden {
  visibility: hidden;
}
.MuiDrawer-docked .Chat {
  background: transparent;
}
.MuiDrawer-docked .Chat #message-box {
  background: transparent;
}
.MuiDrawer-docked .Chat #message-box .fui-ChatMyMessage__author {
  display: block;
  position: relative;
  width: auto;
  height: auto;
  overflow: visible;
}
.MuiDrawer-docked .Chat #message-box > div:first-child {
  background: transparent;
  overflow: visible;
  height: fit-content;
  max-height: fit-content;
}
.MuiDrawer-docked .Chat #message-box .fui-FluentProvider {
  background: transparent;
}
.MuiDrawer-docked .Chat #message-box .fui-Chat {
  gap: 2px;
  padding: 1rem 0;
  overflow: hidden;
}
.MuiDrawer-docked .Chat #message-box .fui-Chat > div > .fui-ChatMessage {
  width: 100%;
}
.MuiDrawer-docked .Chat #message-box .fui-Chat .fui-ChatMessage__body {
  width: 100%;
  border: none;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.08);
  padding: 10px 14px 12px !important;
  box-shadow: none;
  display: flex;
}
.MuiDrawer-docked .Chat #message-box .fui-Chat .fui-ChatMessage__body > div {
  width: 100%;
  display: flex;
  gap: 10px;
}
.MuiDrawer-docked .Chat #message-box .fui-Chat .fui-ChatMessage__body > div:empty {
  display: none;
}
.MuiDrawer-docked .Chat #message-box .fui-Chat .fui-ChatMessage__body > div .ms-Persona {
  flex: 1;
}
.MuiDrawer-docked .Chat #message-box .fui-Chat .fui-ChatMessage__body > div .ms-Persona ~ div {
  display: flex;
  flex: 100;
}
.MuiDrawer-docked .Chat #message-box .fui-Chat .fui-ChatMessage__body > div .fui-ChatMessage {
  margin: 0;
}
.MuiDrawer-docked .Chat #message-box .fui-Chat .fui-ChatMessage__body[data-ui-id="chat-composite-message"] {
  box-shadow: none;
  display: flex;
  padding: 0 !important;
  margin: 0 !important;
}
.MuiDrawer-docked .Chat #message-box .fui-Chat .fui-ChatMyMessage__body[data-ui-id="chat-composite-message"] {
  box-shadow: none;
  display: flex;
  width: 100%;
  padding: 0 !important;
  margin: 0 !important;
}
.MuiDrawer-docked .Chat #message-box .fui-Chat .fui-ChatMyMessage__body > div {
  width: 100%;
  display: flex;
  gap: 10px;
}
.MuiDrawer-docked .Chat #message-box .fui-Chat .fui-ChatMyMessage__body > div > div {
  display: flex;
  flex: 100;
}
.MuiDrawer-docked .Chat #message-box .fui-Chat .fui-ChatMyMessage__body > div .ms-Persona {
  flex: 1;
}
.MuiDrawer-docked .Chat #message-box .fui-Chat .fui-ChatMyMessage__body .fui-ChatMyMessage {
  padding: 0 !important;
}
.MuiDrawer-docked .Chat #message-box .fui-Chat .fui-ChatMyMessage__body:hover ~ .fui-ChatMyMessage__actions {
  box-shadow: none;
}
.MuiDrawer-docked .Chat #message-box .fui-Chat .fui-ChatMyMessage__body .fui-ChatMyMessage__actions {
  box-shadow: none;
  top: -4px;
}
.MuiDrawer-docked .Chat #message-box .fui-Chat .fui-ChatMyMessage__body .fui-ChatMyMessage__actions:hover {
  box-shadow: none;
}
.MuiDrawer-docked .Chat #message-box .fui-Chat .fui-ChatMyMessage__body .fui-ChatMyMessage__actions i {
  stroke: #1379ce;
  color: #1379ce;
}
.MuiDrawer-docked .Chat #message-box .fui-Chat .fui-ChatMyMessage__body .fui-ChatMyMessage__author {
  flex: 100;
  max-width: fit-content;
  width: fit-content;
}
.MuiDrawer-docked .Chat #message-box .fui-Chat .fui-ChatMyMessage__body .fui-ChatMyMessage__author > span {
  font-weight: 600;
  color: #333333;
}
.MuiDrawer-docked .Chat #message-box .fui-Chat .fui-ChatMyMessage__body .ui-chat__message__content > div {
  display: block;
}
.MuiDrawer-docked .Chat #message-box .fui-Chat .fui-ChatMyMessage__body .ms-Stack > .ms-StackItem {
  max-width: fit-content;
}
.MuiDrawer-docked .Chat #message-box .fui-Chat .fui-ChatMyMessage {
  width: 100%;
  border: none;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.08);
  padding: 10px 14px 12px !important;
  box-shadow: none;
  display: flex;
}
.MuiDrawer-docked .Chat ul {
  border: none;
}
.MuiDrawer-docked .Chat ul li {
  border: none;
}
.MuiDrawer-docked .Chat .ui-chat {
  border-top-style: solid;
  background: transparent;
  gap: 5px;
  border: none;
}
.MuiDrawer-docked .Chat .ui-chat .ui-chat__item {
  align-items: flex-start;
  padding: 10px 14px 12px;
  gap: 10px;
  background: #ffffff;
  border: none;
  border-radius: 8px;
  overflow: visible;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.08);
  z-index: 0;
  transition: all 0.15s;
}
.MuiDrawer-docked .Chat .ui-chat .ui-chat__item .ui-chat__item__gutter {
  margin: 5px 0 0;
}
.MuiDrawer-docked .Chat .ui-chat .ui-chat__item .ui-chat__item__gutter:empty {
  display: none;
}
.MuiDrawer-docked .Chat .ui-chat .ui-chat__item .ui-chat__item__message {
  margin: 0;
  padding: 0;
  width: 100%;
}
.MuiDrawer-docked .Chat .ui-chat .ui-chat__item .ui-chat__item__message > .ui-flex {
  justify-content: flex-start !important;
  width: 100%;
}
.MuiDrawer-docked .Chat .ui-chat .ui-chat__item .ui-chat__item__message > .ui-flex > div {
  width: 100%;
  display: flex;
  min-width: -webkit-fill-available;
}
.MuiDrawer-docked .Chat .ui-chat .ui-chat__item .ui-chat__item__message .ui-chat__message {
  padding: 0;
  margin: 0;
  display: flex;
  min-width: 100%;
  flex-direction: column;
}
.MuiDrawer-docked .Chat .ui-chat .ui-chat__item .ui-chat__item__message .ui-chat__message .ui-chat__message__actions {
  top: -4px;
  right: -4px;
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border: none;
}
.MuiDrawer-docked .Chat .ui-chat .ui-chat__item .ui-chat__item__message .ui-chat__message .ui-chat__messageheader .ui-chat__message__author .ui-text {
  font-weight: bold;
}
.MuiDrawer-docked .Chat > div:first-child {
  background: transparent;
  overflow: visible;
  height: fit-content;
}
.MuiDrawer-docked .Chat > div:first-child > div:first-child {
  overflow: visible;
  height: fit-content;
}
.MuiDrawer-docked .Chat > div:first-child > div > div {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.MuiDrawer-docked .Chat > div:first-child > div > div > .ms-Stack:first-child {
  margin: 0;
}
.MuiDrawer-docked .Chat > div:first-child > div > div:not(#message-box) .ms-Stack {
  border-radius: 10px;
}
.MuiDrawer-docked .Chat > div:first-child > div > div:not(#message-box) .ms-Stack .ms-TextField-fieldGroup {
  border-radius: 10px;
}
.MuiDrawer-docked .Chat > div:first-child > div > div:not(#message-box) [data-testid="mention-suggestion-list-container"] {
  top: 100%;
  bottom: unset !important;
  left: 10px !important;
  right: 0;
  max-width: calc(80%) !important;
}
.MuiDrawer-docked .Chat > div:first-child > div > div:not(#message-box) [data-testid="mention-suggestion-list-container"] > .ms-StackItem {
  display: none;
}
.MuiDrawer-docked .Chat > div:first-child > div > div:not(#message-box) .ui-chat .ui-chat__item:first-child [data-testid="mention-suggestion-list-container"] {
  bottom: 100% !important;
  top: auto !important;
}
.MuiDrawer-docked .Chat > div:first-child > div > div:not(#message-box) [data-ui-id="mention-suggestion-list"] {
  padding: 5px;
  gap: 0;
}
.MuiDrawer-docked .Chat > div:first-child > div > div:not(#message-box) [data-ui-id="mention-suggestion-list"] [data-ui-id="mention-suggestion-item"] > .ms-Stack {
  cursor: pointer;
  width: 100%;
}
.MuiDrawer-docked .Chat > div > .ms-Stack {
  margin: 0 !important;
}
.MuiDrawer-docked .Chat .ms-TextField .ms-TextField-fieldGroup {
  border-radius: 10px;
}
.MuiDrawer-docked .Chat > [class*="body-"] > .ms-Stack > .ms-Stack {
  border-radius: 10px;
}
.MuiDrawer-docked .Chat #message-box .fui-Chat .fui-ChatMyMessage__body > div > div {
  flex: auto;
}
.MuiDrawer-docked .Chat #message-box .fui-Chat .fui-ChatMessage__body .fui-ChatMessage__author {
  overflow: hidden;
}
.MuiDrawer-docked .Chat #message-box .fui-Chat .fui-ChatMessage__body .fui-ChatMessage__author > span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.MuiDrawer-docked .Chat #message-box .fui-Chat .fui-ChatMessage__body > div .ms-Persona ~ div {
  max-width: calc(100% - 42px);
}
.MuiDrawer-docked .Chat #message-box .fui-Chat .fui-ChatMessage__body > div .fui-ChatMessage {
  max-width: 100%;
}
.MuiDrawer-docked .Chat #message-box .fui-Chat .fui-ChatMessage__body[data-ui-id="chat-composite-message"] > div:first-child {
  flex-wrap: nowrap;
  white-space: nowrap;
}
.MuiDrawer-docked .Chat #message-box .fui-Chat .fui-ChatMyMessage__body .fui-ChatMyMessage__author {
  overflow: hidden;
}
.MuiDrawer-docked .Chat #message-box .fui-Chat .fui-ChatMyMessage__body .fui-ChatMyMessage__author > span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.MuiDrawer-docked .Chat #message-box .fui-Chat .fui-ChatMyMessage__body .fui-ChatMyMessage {
  max-width: calc(100% - 42px);
}
.MuiDrawer-docked .Chat #message-box .fui-Chat .fui-ChatMyMessage__body .fui-ChatMyMessage__actions {
  height: 25px;
  max-height: 30px;
}
.MuiDrawer-docked .Chat #message-box .fui-Chat .fui-ChatMyMessage__body > div .ms-Persona ~ div.fui-ChatMyMessage {
  min-width: unset;
}
.MuiDrawer-docked .Chat #message-box .fui-Chat .fui-ChatMyMessage__body > div .ms-Persona ~ div.fui-ChatMyMessage > .fui-ChatMyMessage__body > .ms-Stack > div > div {
  display: flex;
  width: 100%;
}
.MuiDrawer-docked .Chat #message-box .fui-Chat .fui-ChatMyMessage__body > div .ms-Persona ~ div.fui-ChatMyMessage > .fui-ChatMyMessage__body > .ms-Stack > div > div .ms-TextField-wrapper .ms-TextField-fieldGroup {
  width: 100%;
}
.MuiDrawer-docked .Chat #message-box .fui-Chat .fui-ChatMyMessage__body > div .ms-Persona ~ div {
  max-width: 100%;
  min-width: 100%;
}
.MuiDrawer-docked .Chat #message-box .fui-Chat .fui-ChatMyMessage__body[data-ui-id="chat-composite-message"] > div:first-child {
  max-width: calc(100% - 32px);
  flex-wrap: nowrap;
  white-space: nowrap;
}
.MuiDrawer-docked .Chat #message-box .fui-Chat .fui-ChatMyMessage__body[data-ui-id="chat-composite-message"].fui-ChatMyMessage__details {
  flex: 1;
  min-width: 65px;
}
.loader-wrapper {
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 15px 0 0;
}
.loader-wrapper .loading.typing_loader {
  -moz-animation: typing 1s linear infinite alternate;
  -webkit-animation: typing 1s linear infinite alternate;
  animation: typing 1s linear infinite alternate;
  border-radius: 50%;
  height: 5px;
  width: 5px;
}
.fui-ChatMyMessage__body .loader-wrapper {
  align-self: center;
  flex: 1;
  max-width: 35px;
  padding: 0;
  width: 35px;
}
.fui-ChatMyMessage__body .loading.typing_loader {
  left: -8px;
  position: relative;
}
@keyframes typing {
  0% {
    background-color: #000000;
    box-shadow: 8px 0 0 0 rgba(0, 0, 0, 0.2), 16px 0 0 0 rgba(0, 0, 0, 0.2);
  }
  25% {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 8px 0 0 0 #000000, 16px 0 0 0 rgba(0, 0, 0, 0.2);
  }
  75% {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 8px 0 0 0 rgba(0, 0, 0, 0.2), 16px 0 0 0 #000000;
  }
}
@-webkit-keyframes typing {
  0% {
    background-color: #000000;
    box-shadow: 8px 0 0 0 rgba(0, 0, 0, 0.2), 16px 0 0 0 rgba(0, 0, 0, 0.2);
  }
}
@-moz-keyframes typing {
  0% {
    background-color: #000000;
    box-shadow: 8px 0 0 0 rgba(0, 0, 0, 0.2), 16px 0 0 0 rgba(0, 0, 0, 0.2);
  }
}
